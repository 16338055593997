import '../stylesheets/posts/default.css';
import '../stylesheets/posts/nav.css';
import '../stylesheets/github-markdown.css';

import '../javascripts/posts.js';
import AnchorJS from 'anchor-js';

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import ruby from 'highlight.js/lib/languages/ruby';
import css from 'highlight.js/lib/languages/css';
import bash from 'highlight.js/lib/languages/bash';
import 'highlight.js/styles/github.css';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('css', css);
hljs.registerLanguage('bash', bash);

document.addEventListener("DOMContentLoaded", (event) => {
  const anchors = new AnchorJS();
  anchors.options = {
    icon: '#'
  };
  anchors.add('h2');
  anchors.add('h3');

  document.querySelectorAll('pre code').forEach((el) => {
    hljs.highlightElement(el);
  });
});
